const periodModule = {
    namespaced: true,
    state: () => ({
        selectedSellerUuid: "",
        selectedPeriod: "",
        selectedWeek: "",
        refreshTrigger: 0,
        periodNotEditableReason: "",
        periodContract: null,
        currentBonus: {},
        periodSaleValue: 0,
        periods: [],
        selectedPeriodIndex: 0,
    }),
    mutations: {
        changeSeller(state, sellerUuid) {
            state.selectedSellerUuid = sellerUuid;
            //localStorage.setItem("selected-seller-uuid", sellerUuid);
        },
        changePeriod(state, period) {
            state.selectedPeriod = period;
            //localStorage.setItem("selected-period", period);
        },
        changeWeek(state, week) {
            console.log("changing week: ", week);
            state.selectedWeek = week;
            console.log("state.selectedWeek: ", state.selectedWeek);
        },
        changePeriodByStart(state, periodStartStr) {
            const index = state.periods.findIndex(period => period.start === periodStartStr);
            state.selectedPeriodIndex = index;
            state.selectedPeriod = state.periods[index];
        },
        triggerRefresh(state) {
            state.refreshTrigger++;
        },
        changePeriodNotEditableReason(state, reason) {
            state.periodNotEditableReason = reason;
        },
        changePeriodContract(state, contract) {
            state.periodContract = contract;
        },
        changePeriodSaleValue(state, saleValue) {
            state.periodSaleValue = saleValue;
        },
        changePeriodLadderProgress(state, progress) {
            state.periodLadderProgress = progress;
        },
        setPeriods(state, periods) {
            state.periods = periods;
        },
        setSelectedPeriodIndex(state, index) {
            state.selectedPeriodIndex = index;
            state.selectedPeriod = state.periods[state.selectedPeriodIndex];
        },
        nextPeriod(state) {
            state.selectedPeriodIndex += 1;
            state.selectedPeriod = state.periods[state.selectedPeriodIndex];
        },
        prevPeriod(state) {
            state.selectedPeriodIndex -= 1;
            state.selectedPeriod = state.periods[state.selectedPeriodIndex];
        },
        updateCurrentBonus(state) {
            if (state.periodContract == null
                || !state.periodContract.bonus_levels
                || state.periodContract.bonus_levels.length == 0
            ) {
                state.currentBonus.has_data = false;
                state.currentBonus.n_levels = 0;
                state.currentBonus.i_level = 0;
                state.currentBonus.rate = 0;
                state.currentBonus.next_level_start = null;
                return;
            }

            if (state.periodContract.bonus_levels.length == 1 &&
                state.periodContract.bonus_levels[0].bonus_start_value == 0 &&
                state.periodContract.bonus_levels[0].bonus_rate == 0
            ) {
                state.currentBonus.has_data = false;
                state.currentBonus.n_levels = 0;
                state.currentBonus.i_level = 0;
                state.currentBonus.rate = 0;
                state.currentBonus.next_level_start = null;
                return;
            }

            state.currentBonus.has_data = true;

            var iCurLevel = -1;
            var nextLevelStartValue = null;
            var nextLevelRate = null;
            var curLevelStartValue = null;
            state.periodContract.bonus_levels.some(function (bonusLevel, iLevel) {
                if (bonusLevel.bonus_start_value > state.periodLadderProgress) {
                    nextLevelStartValue = bonusLevel.bonus_start_value;
                    nextLevelRate = bonusLevel.bonus_rate;
                    return true;
                }
                iCurLevel = iLevel;
                curLevelStartValue = bonusLevel.bonus_start_value;
                return false;
            });

            if (iCurLevel == -1) {
                state.currentBonus.n_levels = state.periodContract.bonus_levels.length + 1;
                state.currentBonus.i_level = -1;
                state.currentBonus.rate = 0;
                curLevelStartValue = 0;
            } else {
                state.currentBonus.i_level = iCurLevel;
                state.currentBonus.rate = state.periodContract.bonus_levels[iCurLevel].bonus_rate;
            }

            //var remainingUntilNext = nextLevelStartValue - state.periodSaleValue;
            var curLevelLength = nextLevelStartValue - curLevelStartValue;
            if (nextLevelStartValue != null) {
                state.currentBonus.next_level_start = nextLevelStartValue;
                state.currentBonus.next_level_rate = nextLevelRate;
                state.currentBonus.percent = 100*(state.periodLadderProgress - curLevelStartValue)  / curLevelLength;
            } else {
                // On final level
                state.currentBonus.next_level_start = curLevelStartValue;
                state.currentBonus.percent = 100;
                state.currentBonus.next_level_rate = null;
            }
        }
    },
    actions: {
        changeSeller({ commit }, sellerUuid) {
            commit("changeSeller", sellerUuid);
        },
        changePeriod({ commit }, period) {
            commit("changePeriod", period);
        },
        changeWeek({ commit }, week) {
            commit("changeWeek", week);
        },
        changePeriodByStart({ commit }, periodStartStr) {
            commit("changePeriodByStart", periodStartStr);
        },
        triggerRefresh({ commit }) {
            commit("triggerRefresh");
        },
        changePeriodNotEditableReason({ commit }, reason) {
            commit("changePeriodNotEditableReason", reason);
        },
        changePeriodContract({ commit }, contract) {
            commit("changePeriodContract", contract);
            commit("updateCurrentBonus");
        },
        changePeriodSaleValue({ commit }, saleValue) {
            commit("changePeriodSaleValue", saleValue);
            //commit("updateCurrentBonus");
        },
        changePeriodLadderProgress({ commit }, progress) {
            commit("changePeriodLadderProgress", progress);
            commit("updateCurrentBonus");
        },
        nextPeriod({ commit }) {
            commit("nextPeriod");
        },
        prevPeriod({ commit }) {
            commit("prevPeriod");
        },
        generatePeriods({ commit }) {
            const periodRange = 24;
            const now = new Date();
            const currentPeriodStart = startOfMonth(now);
            let periods = [];

            let curPeriodIndex = 0;
            for (let offset = -periodRange; offset <= periodRange; offset++) {
                const targetPeriodStart = addMonths(currentPeriodStart, offset);
                //const targetPeriodEnd = addDays(endOfMonth(targetPeriodStart), 1);
                const targetPeriodEnd = endOfMonth(targetPeriodStart);

                periods.push({
                    start: format(targetPeriodStart, 'yyyy-MM-dd'),
                    end: format(targetPeriodEnd, 'yyyy-MM-dd'),
                    start_date: targetPeriodStart,
                    end_date: targetPeriodEnd,
                });

                // Select current month
                if (offset == 0) {
                    //this.selectedPeriodIndex = periods.length - 1;
                    curPeriodIndex = periods.length - 1;
                }
            }

            commit("setPeriods", periods);
            commit("setSelectedPeriodIndex", curPeriodIndex);
            //this.periods = periods;
        },
    },
    getters: {
        selectedSellerUuid: (state) => state.selectedSellerUuid,
        selectedPeriod: (state) => state.selectedPeriod,
        selectedWeek: (state) => state.selectedWeek,
        refreshTrigger: (state) => state.refreshTrigger,
        periodNotEditableReason: (state) => state.periodNotEditableReason,
        periodContract: (state) => state.periodContract,
        currentBonus: (state) => state.currentBonus,
        periodSaleValue: (state) => state.periodSaleValue,
        periodLadderProgress: (state) => state.periodLadderProgress,
        selectedPeriodMonthName: (state) => {
            if (state.selectedPeriod == null) {
                return "";
            }
            return format(state.selectedPeriod.start, "MMMM");
        }
    },
};

import { format, startOfMonth, endOfMonth, addMonths } from 'date-fns';
export default periodModule;
//import Vuex from 'vuex';

// Vuex plugin to automatically run the initialization
/*
function initPeriods(store) {
    store.dispatch("periodModule/generatePeriods");
}*/

