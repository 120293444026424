
export function formatAmount({ amount, roundByThousand, doColor, hideZero, hideCurrency }) {
    if (hideZero && amount == 0) {
        return "<pre> </pre>";
    }
    var cls = "";
    var prefix = "";
    if (doColor) {
        if (amount > 0) {
            prefix = "+";
            cls = "green";
        } else if (amount < 0) {
            prefix = "-";
            cls = "red";
        }
    }
    var unit = "";
    if (roundByThousand && amount > 1000) {
        amount = Math.round(amount) / 1000
        amount = Math.round(amount * 10) / 10
        if (hideCurrency) {
            unit = "k";
        } else {
            unit = "kSEK";
        }
    } else {
        amount = Math.round(amount * 100) / 100;
        if (!hideCurrency) {
            unit = "SEK";
        }
    }
    return `<span class="${cls}">${prefix}${amount}</span>${unit}`;
}

export function applyRate({ amount, rate, decimals }) {
    amount = amount * (1.0 + (rate/100));
    if (decimals != -1) {
        amount = Math.round(amount * 10**decimals) / 10**decimals;
    }
    return amount;
}

export function dateInPeriod({ period, dateStr }) {
    let date = new Date(dateStr);
    const inPeriod = date >= period.start_date && date <= period.end_date;
    return inPeriod;
}

export function getInitials({ firstName, lastName }) {
    let initials = "";
    if (firstName.length > 0) {
        initials += firstName[0];
    }

    if (lastName.length > 0) {
        initials += lastName[0];
    }

    return initials;
}

import { formatDistanceToNow } from 'date-fns';
export function formatTimeSince({ datetime }) {
    return formatDistanceToNow(datetime, { addSuffix: true })
}

export function weightedAverage({ values, weights }) {
    let totalWeight = 0;
    let weightedSum = 0;

    values.forEach((value, index) => {
        weightedSum += value * weights[index];
        totalWeight += weights[index];
    });

    return totalWeight > 0 ? weightedSum / totalWeight : 0;
}

export function roundToDecimals( {value, decimals }) {
    value = Math.round(value * 10**decimals) / 10**decimals;
    return value;
}

/*
export function periodToMonthName( {periodObj } ) {
    if (periodObj == null) {
        return "";
    }
    return format(this.selectedPeriod.start, "MMMM");
}*/












