export const GRAPH_TITLE_SIZE = 24;
export const GRAPH_TEXT_COLOR_LIGHT_MODE = "#000";
export const GRAPH_TEXT_COLOR_DARK_MODE = "#FFF";

export function strToColor(text) {
    const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    const rgbToHex = (r, g, b) => {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    function stringToHash(string) {
        let hash = 0;
        if (string.length == 0) return hash;
        for (let i = 0; i < string.length; i++) {
            var char = string.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return hash;
    }

    const r = Math.abs(stringToHash(text + "red")) % 256;
    const g = Math.abs(stringToHash(text + "green")) % 256;
    const b = Math.abs(stringToHash(text + "blue")) % 256;
    var col = rgbToHex(r, g, b);
    return col;
}
