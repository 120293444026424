//import Vue, { createApp } from '@vue/compat';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

import { BootstrapVue3 } from 'bootstrap-vue-3';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

// Bootstrap5 things
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css' // optional for styling


//import { BootstrapVue } from 'bootstrap-vue'

//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

//import {createBootstrap} from 'bootstrap-vue-next'

// Add the necessary CSS
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

// Since datepicker in bootstrap-vue doesn't work
// import VueDatePicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'
//import VCalendar from 'v-calendar';
//import 'v-calendar/style.css';
//

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

// Toastr configuration (optional, customize as needed)
toastr.options.closeButton = true;
//toastr.options.closeButton = false;
//toastr.options.debug = false;
toastr.options.newestOnTop = false;
toastr.options.progressBar = false;
toastr.options.positionClass = "toast-bottom-full-width";
toastr.options.preventDuplicates = true;
toastr.options.onclick = null;
toastr.options.showDuration = "300";
toastr.options.hideDuration = "1000";
toastr.options.timeOut = "5000";
toastr.options.extendedTimeOut = "2000";
//toastr.options.showEasing = "swing";
//toastr.options.hideEasing = "linear";
//toastr.options.showMethod = "fadeIn";
//toastr.options.hideMethod = "fadeOut;

// NOTE(jakob): This needs to happen early, otherwise some components don't get the right URL.
const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + 3333 : ''}`;
console.log("baseURL: ", baseURL);
axios.defaults.baseURL = baseURL;

const token = localStorage.getItem('access-token');
if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// Intercept all responses, if the access token has expired, request a
// new one using the refresh token. If the refresh token is not valid, logout
axios.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config;
    /*
    console.log("Intercepting error", error);
    console.log("Intercepting response", originalRequest);
    console.log("error.response", error.response);
    console.log("error: ", error);*/
    //console.log("originalRequest._retry: ", originalRequest._retry);
    // Need to prevent requests to "/api/refresh" because it seems like it otherwise gets caught in a forever loop
    if (error.response && error.response.status === 401 && !originalRequest._retry && error.config.url != "/api/refresh") {
        console.log("Got 401. Will request token")
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem('refresh-token');
        //console.log("refreshToken: ", refreshToken);
        if (!refreshToken) {
            store.dispatch("auth/logout", { router: router });
            // router.push("/login"); // Redirect to login page
            return Promise.reject(error);
        }
        try {
            console.log("Trying to refresh token");
            // TODO why is this being passed here? It's also read in the action
            //await store.dispatch('auth/refreshToken', refreshToken);
            const newAccessToken = await store.dispatch("auth/refreshToken");
            console.log("newAccessToken: ", newAccessToken);
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            console.log("retruning request");
            return axios(originalRequest);
        } catch (refreshError) {
            console.log("Error caught in axios interceptor");
            // If refresh token is expired or any error occurs during refresh
            //store.dispatch('auth/logout'); // Clear user state
            store.dispatch("logout", { router: router });
            console.log("refreshing token failed");
            // router.push('/login'); // Redirect to login page
            return Promise.reject(refreshError);
        }
    }
    return Promise.reject(error);
});


//Vue.use(BootstrapVue)
const app = createApp(App);

// app.use(store).use(router)
app.use(store).use(router).use(BootstrapVue3);
app.use(
    VueTippy,
    {
        directive: 'tippy',
        component: 'tippy',
        componentSingleton: 'tippy-singleton',
        animateFill: true,
        defaultProps: {
            placement: "bottom",
            allowHTML: true,
            arrow: true,
            theme: "dark",
        },
    },
)

import { createVfm } from 'vue-final-modal'
const vfm = createVfm()
app.use(vfm)
import 'vue-final-modal/style.css'

//app.use(store).use(router).use(BootstrapVue)
//app.use(store).use(router).use(createBootstrap())
// const app = .use(store).use(router).use(BootstrapVue3).mount('#app');
app.config.globalProperties.$toastr = toastr;
app.component('VueDatePicker', VueDatePicker);
app.mount("#app");


// NOTE(jakob): This makes the scss styles available to all pages.
// All the classes and styles applied in index.sccs are applied everywhere.
// However, this is not what makes the scss variables and mixins available
// everywhere. That is handled in vue.config.js
import './assets/styles/index.scss';

import '@fortawesome/fontawesome-free/css/all.css';

