<template>
    <div class="sale_stats_container">
        <SalesStatsCard
            :title="monthName + ' sales'"
            icon="fa-sack-dollar"
            :stats=thisMonthPerforanceStats
        />
        <SalesStatsCard
            :title="monthName + ' payout'"
            icon="fa-sack-dollar"
            :stats=thisMonthPayoutStats
        />
        <SalesStatsCard
            title="This year"
            icon="fa-calendar-days"
            :stats=thisYearStats
        />
        <BonusStatsCard

        />
    </div>
</template>
<script>
    import axios from 'axios';
    import { format } from 'date-fns';
    import { mapGetters } from 'vuex';

    import SalesStatsCard from '@/components/SalesStatsCard.vue';
    import BonusStatsCard from '@/components/BonusStatsCard.vue';

    import { formatAmount } from "@/utils/general.js"
    import * as Keys from '@/utils/keys';

    export default {
        components: {
            SalesStatsCard,
            BonusStatsCard,
        },
        props: {
            title: String,
            selectedPeriod: Object,
        },
        data() {
            return {
                KEY_PERIOD_COMMISSION: "period_commission",
                KEY_PERIOD_N_NEW: "period_n_new",
                KEY_PERIOD_N_CUSTOMERS: "period_n_customers",
                KEY_PERIOD_SALE_VALUE: "period_sale_value",
                periodsStats: {},
                ytdStats: {},
                selectedMonth: "",
            }
        },
        methods: {
            formatAmount,
            async fetchStats(sellerUuid, periodStart) {
                if (!sellerUuid || !periodStart) {
                    console.log("fetchStats called with invalid seller or period. Seller:", sellerUuid, "period:", periodStart);
                    return;
                }
                try {
                    const response = await axios.get("/api/get_stats_for_period", {
                        params: {
                            "seller_uuid": sellerUuid,
                            "period": periodStart,
                        }
                    });
                    this.periodsStats[periodStart] = response.data["current_period"];
                    this.ytdStats[periodStart] = response.data["ytd"];
                } catch (error) {
                    console.error("Failed to fetch stats", error);
                    this.$toastr.error("Error fetching stats");
                }
            },
        },
        computed: {
            ...mapGetters("period", [
                "periodContract",
            ]),
            monthName() {
                if (this.selectedPeriod == null) {
                    return "";
                }
                return format(this.selectedPeriod.start, "MMMM");
            },
            periodYtdStats() {
                if (!this.ytdStats || !this.selectedPeriod || !this.ytdStats[this.selectedPeriod.start]) {
                    return null;
                }
                return this.ytdStats[this.selectedPeriod.start];
            },
            currentPeriodStats() {
                if (!this.periodsStats || !this.selectedPeriod || !this.ytdStats[this.selectedPeriod.start]) {
                    return null;
                }
                return this.periodsStats[this.selectedPeriod.start];
            },
            thisMonthPerforanceStats() {
                if (this.currentPeriodStats == null){
                    return [];
                }
                const stats = [];
                //<span v-html="formatAmount({ amount: item.value, roundByThousand: false, doColor: true, hideZero: false, })">
                stats.push({
                    "title": "sales",
                    "value": this.currentPeriodStats.period_n_new,
                });
                stats.push({
                    "title": "customers",
                    "value": this.currentPeriodStats.period_n_customers,
                });
                stats.push({
                    "title": "earned in " + this.monthName,

                    "value": this.formatAmount({ amount: this.currentPeriodStats[Keys.KEY_PERIOD_COMMISSION_EARNED], roundByThousand: false, doColor: true, hideZero: false, }), // TODO
                });
                stats.push({
                    "title": "sold for",
                    "value": this.formatAmount({ amount: this.currentPeriodStats.period_sale_value, roundByThousand: true, doColor: false, hideZero: false, }), // TODO,
                });
                return stats;
            },
            thisMonthPayoutStats() {
                if (this.currentPeriodStats == null) {
                    return [];
                }
                const stats = [];
                stats.push({
                    "title": "commissions payout",
                    "value": formatAmount({ amount: this.currentPeriodStats?.period_commission, roundByThousand: false, doColor: false, hideZero: false, }),
                });

                stats.push({
                    "title": "base salary",
                    "value": formatAmount({ amount: this.periodContract?.base_salary, roundByThousand: false, doColor: false, hideZero: false, }),
                });

                stats.push({
                    "title": "total payout in " + this.monthName,
                    "value": formatAmount({ amount: this.periodContract?.base_salary + this.currentPeriodStats?.period_commission, roundByThousand: false, doColor: true, hideZero: false, }),
                });

                /*
                formatAmount({
                        amount: stats?.period_commission + ,
                        roundByThousand: false, doColor: true, hideZero: false, })*/
                return stats;
            },
            thisYearStats() {
                if (this.periodYtdStats == null) {
                    return [];
                }
                const stats = [];
                stats.push({
                    "title": "sales",
                    "value": this.periodYtdStats.period_n_new,
                });
                stats.push({
                    "title": "customers",
                    "value": this.periodYtdStats.period_n_customers,
                });
                stats.push({
                    "title": "sold for",
                    "value": this.formatAmount({ amount: this.periodYtdStats.period_sale_value, roundByThousand: true, doColor: false, hideZero: false, }),
                });
                // TODO show commissions earnt so far
                return stats;
            },
        },
        mounted() {
        }
    };

</script>
<style lang="scss" scoped>
    @import "@/assets/styles/SalesStats.scss";
</style>
