import { createStore } from 'vuex';
import auth from './modules/auth';
import theme from './modules/theme';
import period from './modules/period';
import products from './modules/products';

// Create a new store instance.
const store = createStore({
    modules: {
        auth,
        theme,
        period,
        products,
    },
});

store.dispatch("period/generatePeriods");

export default store;
