import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import store from '../store';
// import AccountView from '../views/AccountView.vue'
// Assuming you have a SalesView.vue component

const routes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView,
        meta: {requiresAuth: true, requiresAdmin: false, requiresHr: false}
    },
    {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '../views/AccountView.vue'),
        meta: {requiresAuth: true, requiresAdmin: false, requiresHr: false}
    },
    {
        path: '/sales',
        name: 'sales',
        component: () => import(/* webpackChunkName: "sales" */ '../views/SalesView.vue'),
        // TODO
        meta: {requiresAuth: true, requiresAdmin: false, requiresHr: false}
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "sales" */ '../views/LoginView.vue'),
        meta: {requiresAuth: false, requiresAdmin: false, requiresHr: false}
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "sales" */ '../views/SettingsView.vue'),
        meta: {requiresAuth: true, requiresAdmin: false, requiresHr: false}
    },
    {
        path: '/contracts',
        name: 'contracts',
        component: () => import(/* webpackChunkName: "sales" */ '../views/ContractsView.vue'),
        meta: {requiresAuth: true, requiresAdmin: false, requiresHr: false}
    },
    {
        path: '/organization',
        name: 'organization',
        component: () => import(/* webpackChunkName: "sales" */ '../views/OrganizationView.vue'),
        meta: {requiresAuth: true, requiresAdmin: false, requiresHr: true}
    },
    {
        path: '/payroll',
        name: 'payroll',
        component: () => import(/* webpackChunkName: "sales" */ '../views/PayoutView.vue'),
        meta: {requiresAuth: true, requiresAdmin: false, requiresHr: true}
    },
    {
        path: '/scoreboard',
        name: 'scoreboard',
        component: () => import(/* webpackChunkName: "sales" */ '../views/ScoreboardView.vue'),
        meta: {requiresAuth: true, requiresAdmin: false, requiresHr: false}
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/dashboard',
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters['auth/isAuthenticated'];
    // const userData = store.getters['auth/userData'];
    const isOrganizationAdmin = store.getters['auth/isOrganizationAdmin'];
    const hasHrAccess = store.getters['auth/hasHrAccess'];

    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        next('/login');
    } else if (to.matched.some(record => record.meta.requiresAdmin) && !isOrganizationAdmin) {
        if (isAuthenticated) {
            next('/dashboard');
        } else {
            next('/login');
        }
    } else if (to.matched.some(record => record.meta.requiresHr) && !hasHrAccess) {
        if (isAuthenticated) {
            next('/dashboard');
        } else {
            next('/login');
        }
    } else if (to.path === '/' && !isAuthenticated) {
        // Not logged in and accessing root, redirect to login
        next('/login');
    } else if (to.path === '/' && isAuthenticated) {
        // Logged in and accessing root, redirect to dashboard
        next('/dashboard');
    } else {
        next();
    }
});

export default router
