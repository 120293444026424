<template>
    <div :class="{'is-visible': isVisible}" class="nav_sidebar">
        <div class="logo_container">
            <img v-show="isDarkMode" class="logo" alt="TinPil logo" src="../assets/logos/tinpil_white.png">
            <img v-show="!isDarkMode" class="logo" alt="TinPil logo" src="../assets/logos/tinpil_original.png">
        </div>
        <div class="d-flex justify-content-center">
            <div class="theme_selector_container form-check form-switch">
                <label
                    class="form-check-label"
                    for="themeSwitch"
                    >
                    <i class="fa-regular fa-moon"></i>
                </label>
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="themeSwitch"
                    @change="toggleTheme"
                    :checked="isDarkMode"
                    >
            </div>
        </div>
        <div v-if="userData" class="user_data_container">
                <!--<div class="d-flex justify-content-center pt-3">
                </div>-->
                <div class="profile_picture_container">
                    <img v-if="userData.profile_pic_data" alt="Profile picture" :src="userData.profile_pic_data">
                    <b-avatar
                        v-else
                        :text="userData.initials"
                        text-size="rem"
                        size="7rem"
                        :style="{background: userData?.user_color + '!important'}"
                        >
                    </b-avatar>
                </div>
                <p class="text-center users_name"><strong>{{ userData.full_name }}</strong></p>
                <p class="text-center">
                <strong> {{ Const.ROLE_TO_FE_ROLE[userData.role] }} </strong>
                at
                <strong>{{ userData.organization_name }}</strong>
                </p>
                <p v-show = "userData?.is_organization_admin" class="text-center">Admin</p>
        </div>
        <div class="nav_lists_container">
            <ul class="primary_pages_list">
                <li v-for="page in accessiblePages" :key="page.path">
                    <router-link
                        :to="page.path"
                        active-class="router_link_active"
                        >
                        <i :class="['fa-solid', page.icon]"></i>
                        <span>{{ page.name }}</span>
                    </router-link>
                </li>
            </ul>
            <div class="nav_bottom_container">
                <div v-if="userData?.feedback_link?.length > 0" class="feedback_link_container">
                    <a class="feedback_link" :href="userData?.feedback_link" target="_blank">Feedback</a>
                </div>
                <ul class="secondary_pages_bar">
                    <li class="cursor_pointer" @click="doLogout">
                        <a>
                            <i class="fa-solid fa-right-from-bracket"></i>
                            <span>Log out</span>
                        </a>
                    </li>
                    <li>
                        <router-link to="/settings" active-class="router_link_active">
                            <i class="fa-solid fa-cog"></i>
                            <span>Settings</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    //import axios from 'axios';
    import { mapGetters, mapActions } from 'vuex';

    import * as Const from '@/utils/const';
    import * as Keys from '@/utils/keys';

    export default {
        data() {
            return {
                Const,
                // TODO should probably use a shared list with router/index.js
                navItems: [
                    { name: "Dashboard", path: "/dashboard", icon: "fa-square-poll-vertical", requires_admin: false, requires_hr: false},
                    { name: "Sales", path: "/sales", icon: "fa-dollar-sign", requires_admin: false, requires_hr: false},
                    { name: "Contracts", path: "/contracts", icon: "fa-file-invoice-dollar", requires_admin: false, requires_hr: false},
                    { name: "Company", path: "/organization", icon: "fa-building", requires_admin: false, requires_hr: true},
                    { name: "Payroll", path: "/payroll", icon: "fa-receipt", requires_admin: false, requires_hr: true},
                    { name: "Scoreboard", path: "/scoreboard", icon: "fa-trophy", requires_admin: false, requires_hr: false},
                ],
                isVisible: true,
            };
        },
        computed: {
            // Accessing userData from Vuex auth module
            ...mapGetters('auth', ['userData']),
            ...mapGetters('theme', ['isDarkMode']),
            accessiblePages() {
                if (this.userData == null) {
                    return [];
                }
                const pages = [];
                let self = this;
                this.navItems.forEach(function (navItem) {
                    if (navItem.requires_admin && !self.userData[Keys.KEY_IS_ORG_ADMIN]) {
                        return;
                    }
                    if (navItem.requires_hr && !self.userData[Keys.KEY_HAS_HR_ACCESS]) {
                        return;
                    }
                    pages.push(navItem);
                });
                return pages;
            },
            /*
            isDarkMode() {
                return this.$store.getters['theme/isDarkMode'];
            }*/
        },
        methods: {
            ...mapActions("auth", ["logout"]),
            doLogout() {
                this.logout({ router: this.$router });
            },
            ...mapActions("auth", ["refreshToken"]),
            ...mapActions("auth", ["whoami"]),
            ...mapActions("auth", ["clearUserData"]),
            async testApi() {
                //this.logout({ router: this.$router });
                this.clearUserData();
                /*
                const response = await axios.post("/api/test", {
                    // "sale_data": sale,
                    //"seller_uuid": this.selectedSellerUuid,
                });
                console.log("response: ", response);*/
            },
            toggleTheme() {
                this.$store.dispatch('theme/toggleTheme');
                //this.$emit('on-toggle-theme');
            },
        },
        mounted() {
        },
    }
</script>

<style lang="scss">

.nav_sidebar {
    @include shadow;
    color: var(--primary_text_color);
    height: 100%;

    .theme_selector_container {
        font-size: 1.2rem;
        input {
            border: 2px solid var(--faded_text_color);
        }
    }

    .logo_container {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
            width: 70%;
        }
    }

    .user_data_container {
        .profile_picture_container {
            display: flex;
            justify-content: center;
            padding-top: 1rem;
            img {
                border-radius: 50%;
                width: 50%;
            }
        }
        p {
            margin-bottom: 0;
            padding: 0;
        }
    }
    .users_name {
        font-size: 1.5rem;
    }
    .nav_lists_container {
        .primary_pages_list, .secondary_pages_bar {
            li a.router_link_active, li button {
                background: var(--primary_background_color);
                i {
                    color: var(--primary_brand_color) !important;
                }
                span {
                    /*font-weight: $bold_weight;*/
                }
            }
            li {
                height: 100%;
                color: var(--primary_text_color);
                i {
                    font-size: 1.5rem;
                }
                a, button {
                    text-decoration: none;
                    color: var(--primary_text_color);
                }
            }
        }

        .primary_pages_list {
            list-style: none;
            padding: 0;
            margin: 0;
            margin-top: $inner_component_padding;

            li a {
                display: block;
                width: 90%;
                margin-left: 5%;
                border-radius: $default_border_radius;
                color: var(--primary_text_color);
                $padding: 0rem;
                padding-top: $padding;
                padding-bottom: $padding;
                margin-top: 0.3rem;
                display: flex;
                span {
                    font-size: 1.3rem;
                    font-weight: $bold_weight;
                    flex: 3;
                }
                i {
                    margin:auto;
                    flex: 1;
                    text-align: center;
                }
                &:hover {
                    background: var(--primary_background_color) !important;
                }
            }
        }
        .nav_bottom_container {
            width: 100%;
            position: absolute;
            bottom: 0;
            .feedback_link_container {
                padding: 2rem;
                .feedback_link {
                    border-radius: $default_border_radius;
                    border: 2px solid var(--primary_text_color);
                    text-decoration: none;
                    text-align: center;
                    display: block;
                    color: var(--primary_text_color);
                    font-size: 1.5rem;
                    &:hover {
                        color: var(--faded_text_color);
                    }
                }
            }
            li:hover {
                background: var(--primary_background_color) !important;
            }
            .secondary_pages_bar {
                display: flex;
                padding: 0;
                list-style: none;
                margin: 0;
                width: 100%;
                li {
                    width: 50%;
                    a {
                        padding: 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        span {
                            padding-top: 0.2rem;
                        }
                    }
                }
            }
        }
    }

}

</style>
