<template>
    <div class="header">
        <div class="header_bottom_shadow"></div>
        <!--<img alt="TinPil logo" src="../assets/logos/TinPil_White.svg">-->
        <!--
        <div class="theme_selector_container form-check form-switch">
            <label
                class="form-check-label"
                for="themeSwitch"
                >
                <i class="fa-regular fa-moon"></i>
            </label>
            <input
                class="form-check-input"
                type="checkbox"
                id="themeSwitch"
                @change="toggleTheme"
                :checked="isDarkMode"
                >
        </div>
        -->
        <div class="notification_button_container">
            <NotificationsPanel />
            <!--
            <Popper
                arrow
                placement="bottom"
                offsetSkid="-200"
                >
                <div class="notification_button_inner_container">
                    <i class="fa-regular fa-bell"></i>
                    <span class="notifications_badge">1</span>
                </div>
                <template #content>
                    <NotificationsPanel />
                </template>
            </Popper>-->
        </div>
    </div>
</template>

<script>
    import NotificationsPanel from '@/components/NotificationsPanel.vue';
    //import Popper from "vue3-popper";
    export default {
        name: 'AppHeader',
        components: {
            //Popper,
            NotificationsPanel,
        },
        data() {
            return {
            };
        },
        methods: {
            /*
            toggleTheme() {
                this.isDarkMode = !this.isDarkMode;
                const newTheme = this.isDarkMode ? 'dark' : 'light';
                document.body.setAttribute('data-theme', newTheme);
                localStorage.setItem('theme', newTheme);
            },*/
        },
        mounted() {
            //document.body.setAttribute('data-theme', this.isDarkMode ? 'dark' : 'light');
        },
    };
</script>


<style lang="scss">
/*
    .header::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background: transparent;
        z-index: -1;
        // NOTE(jakob): This achieves a similar enough look to the other shadows (maybe there's a better way)
        -webkit-box-shadow: 0px 0px $shadow_blur_radius/4 $shadow_spread_radius/3 var(--card_shadow_color);
        -moz-box-shadow: 0px 0px $shadow_blur_radius/4 $shadow_spread_radius/3 var(--card_shadow_color);
        box-shadow: 0px 0px $shadow_blur_radius/3 $shadow_spread_radius var(--card_shadow_color);
        //-webkit-box-shadow: 0px 0px $shadow_blur_radius/4 $shadow_spread_radius/3 var(--card_shadow_color);
        //-moz-box-shadow: 0px 0px $shadow_blur_radius/4 $shadow_spread_radius/3 var(--card_shadow_color);
        //box-shadow: 0px 0px $shadow_blur_radius/3 $shadow_spread_radius var(--card_shadow_color);
    }*/
    .header {
        //@include shadow_bottom;
        border: 1px solid transparent;

        // Bottom only shadow. Tweaked to achieve a similar enough look to the other shadows
        box-shadow: 0px 10px $shadow_blur_radius calc($shadow_blur_radius*-1) var(--card_shadow_color);
        display: flex;
        justify-content: end;
        align-items: center;
        h1 {
            margin: 1rem;
        }
        .theme_selector_container {
            margin-right: 2rem;;
        }

        .notification_button_container {
            margin-right: $inner_component_padding;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
</style>
