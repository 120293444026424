import axios from 'axios';

const productsModule = {
    namespaced: true,
    state: () => ({
        products: [],
    }),
    actions: {
        async fetchProducts({ commit }) {
            try {
                const resp = await axios.get('/api/get_my_products');
                const products = resp.data;
                commit("setProducts", products);
            } catch (error) {
                console.log("Error fetching products data: ", error);
                throw new Error("Error fetching products data");
            }
        },
    },
    mutations: {
        setProducts(state, products) {
            state.products = products;
        },
    },
    getters: {
        userProducts: (state) => state.products,
    },
};

export default productsModule;
