import axios from 'axios';
import { strToColor } from "@/utils/graphs.js"

// Needed to avoid possible error when paring the stored JSON
const getSafeItem = (key) => {
    try {
        return JSON.parse(localStorage.getItem(key));
    } catch (e) {
        console.error(`Error parsing JSON for key "${key}":`, e);
        return null;
    }
};

const state = {
    token: localStorage.getItem('access-token') || '',
    refreshToken: localStorage.getItem('user-refresh-token') || '',
    status: '',
    // NOTE: Storing this here is necessary because if you do a hard refresh on a 
    // admin-only page, the router wont initially be aware whether you have access or not.
    // I think it's also necessary for oauth callbacks to work.
    user:  getSafeItem("user-data") || null,
};


const getters = {
    isAuthenticated: state => !!state.token,
    isOrganizationAdmin: state => state.user?.is_organization_admin,
    hasHrAccess: state => state.user?.has_hr_access,
    userData: state => state.user,
    authStatus: state => state.status,
};

const actions = {
    // TODO user argument should be named 'credentials'?
    async login({ commit, dispatch }, user) {
        commit('authRequest');
        try {
            const resp = await axios.post('/api/login', user);
            const token = resp.data.access_token;
            const refreshToken = resp.data.refresh_token;
            localStorage.setItem('access-token', token);
            localStorage.setItem('refresh-token', refreshToken);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            // NOTE(jakob): Something's going on with the vue reactivity.
            // If the authSuccess-commit doesn't have an await, watchers of authSuccess
            // will not get triggered.
            await dispatch("fetchUserData");
            await commit('authSuccess', token);
        } catch (err) {
            commit('authError');
            commit("setUserData", null)
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            throw err;
        }
    },
    async logout({ commit }, { router }) {
        console.log("Logging out");
        commit("logout");
        commit("setUserData", null);
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        commit("clearTokens");
        delete axios.defaults.headers.common['Authorization'];
        router.push("/login");
    },
    async refreshToken({ commit }) {
        try {
            const refreshToken = localStorage.getItem('refresh-token');
            if (!refreshToken) {
                // TODO logout?
                console.log("Tried to call refreshToken, but we don't have a refresh token");
                throw new Error("No refresh token available.");
            }
            //const response = await axios.post('/api/refresh', { 'refresh_token': refreshToken });
            const response = await axios.post('/api/refresh', {}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('refresh-token')}`
                }
            })
            const accessToken = response.data.access_token;

            // Update local storage and Axios headers with the new access token
            localStorage.setItem('access-token', accessToken);
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            console.log("committing authSuccess");
            // Optionally update the state to indicate the new token
            commit('authSuccess', accessToken);

            return accessToken; // Return the new access token for immediate use
        } catch (error) {
            console.log("Error caught in refreshToken", error);
            // Handle refresh token failure (e.g., token is invalid or expired)
            commit('authError');
            commit("setUserData", null);
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            delete axios.defaults.headers.common['Authorization'];

            // Redirect user to login or throw an error
            // router.push('/login');
            throw error;
        }
    },
    async whoami() {
        try {
            const response = await axios.post('/api/whoami')
            console.log("response", response);
        } catch (error) {
            console.log("whoami error", error);
        }
        /*
        .then(response => {
            console.log("Ok response from whoami", response);
        })
        .catch(error => {
            console.log("Error from whoami", error);
        });*/
    },
    async fetchUserData({ commit }) {
        try {
            console.log("Fethcing user data");
            const resp = await axios.post('/api/get_user_data');
            // TODO this looks a bit unnecessary
            const userData = resp.data;
            /*
            const userData = {
                email: resp.data.email,
                first_name: resp.data.first_name + " ",
                last_name: resp.data.last_name + " ",
                full_name: resp.data.first_name + " " + resp.data.last_name,
                role: resp.data.role,
                organization_name: resp.data.organization_name,
                user_uuid: resp.data.user_uuid,
                feedback_link: resp.data.feedback_link,
            };*/
            userData.full_name = resp.data.first_name + " " + resp.data.last_name;
            if (resp.data.profile_pic_data) {
                userData.profile_pic_data = `data:image/jpg;base64, ${resp.data.profile_pic_data}`;
            }
            var initials = "";
            if (userData.first_name.length > 0) {
                initials += userData.first_name.charAt(0);
            }
            if (userData.last_name.length > 0) {
                initials += userData.last_name.charAt(0);
            }
            userData.initials = initials;
            userData.user_color = strToColor(resp.data.user_uuid);
            commit("setUserData", userData);
            localStorage.setItem("user-data", JSON.stringify(userData));
        } catch (error) {
            // TODO(jakob) maybe logout
            console.log("Error fetching user data: ", error);
            throw new Error("Error fetching user data");
        }
    },
    async clearUserData({ commit }) {
        commit("setUserData", null);
    }
};

const mutations = {
    authRequest(state) {
        state.status = 'loading';
    },
    authSuccess(state, token) {
        // TODO(jakob): when page is refreshed, the status (and all other states?) get reset to their default value.
        // This isn't called on a refresh so status is reset to an empty string. Places checking auth status are currently
        // checking that it's not equal to "logged_out", but this could probably cause some incorrect behaviour if we refresh
        // when logged out
        state.status = 'success';
        state.token = token;
    },
    authError(state) {
        state.status = 'error';
    },
    logout(state) {
        state.status = 'logged_out';
        state.token = '';
    },
    setUserData(state, userData) {
        state.user = userData;
    },
    clearTokens(state) {
        state.refreshToken = null;
        state.accessToken = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
