<template>
    <div class="avatar_container">
        <!-- TODO size -->
        <img
            v-if="picData"
            alt="Profile picture" :src="picData"
            :style="{width: size, height: size}"
            >
        <b-avatar
            v-else
            :text="getInitials({firstName: firstName, lastName: lastName})"
            :size="size"
            :style="{background: strToColor(userUuid) + '!important'}"
            >
        </b-avatar>
        <i v-if="showNewIndicator"
            class="fa-solid fa-circle is_new_indicator"
            >
        </i>
        <i class="fa-solid action_indicator" :class="actionIconClass"> </i>
    </div>
</template>
<script>

    //import { formatAmount } from "@/utils/general.js"
    import { mapGetters } from 'vuex';

    import { getInitials } from "@/utils/general.js"
    import { strToColor } from "@/utils/graphs.js"
    import * as Keys from '@/utils/keys.js';

    export default {
        components: {
        },
        props: {
            firstName: {
                type: String,
                default: "",
                required: false,
            },
            lastName: {
                type: String,
                default: "",
                required: false,
            },
            userUuid: {
                type: String,
                default: "",
                required: false,
            },
            picData: {
                type: String,
                default: null,
                required: false,
            },
            size: {
                type: String,
                default: "5rem",
                required: false,
            },
            showNewIndicator: {
                type: Boolean,
                default: false,
                required: false,
            },
            actionIconClass: {
                type: String,
                default: "",
                required: false,
            },
        },
        data() {
            return {
                Keys,
            };
        },
        methods: {
            getInitials,
            strToColor,
        },
        computed: {
            ...mapGetters("period", ["currentBonus", "periodContract", "periodLadderProgress"]),
        },
    };
</script>
<style lang="scss" scoped>

.avatar_container {
    position: relative;
    img {
        // TODO size prop
        //width: 5rem;
        //height: 5rem;
        border-radius: 50%;
    }
    .is_new_indicator {
        position: absolute;
        top: 5%;
        left: 5%;
        color: $red;
        font-size: 1rem;
        opacity: 0.9;
    }
    .action_indicator {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 2rem;
    }
}
</style>
