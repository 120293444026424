const themeModule = {
    namespaced: true,
    state: () => ({
        isDarkMode: localStorage.getItem('theme') === 'dark',
    }),
    actions: {
        toggleTheme({ commit }) {
            commit('toggleTheme');
        },
    },
    mutations: {
        toggleTheme(state) {
            state.isDarkMode = !state.isDarkMode;
            localStorage.setItem('theme', state.isDarkMode ? 'dark' : 'light');
            document.body.setAttribute('data-theme', state.isDarkMode ? 'dark' : 'light');
        },
    },
    getters: {
        isDarkMode: (state) => state.isDarkMode,
    },
};

export default themeModule;
